import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface TimeDisplayProps {
	useUTC?: boolean;
	message?: string;
	fixedDate?: Date;
	tz?: boolean;
}

const TimeDisplay: React.FC<TimeDisplayProps> = ({
	useUTC = false,
	fixedDate,
	tz = true,
}) => {
	const [time, setTime] = useState<string>('');
	const [date, setDate] = useState<string>('');
	const [timeZone, setTimeZone] = useState<string>('');

	useEffect(() => {
		const updateTime = () => {
			const now = fixedDate || new Date();
			const timeOptions: Intl.DateTimeFormatOptions = {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false,
				timeZone: useUTC ? 'UTC' : undefined,
			};
			const dateOptions: Intl.DateTimeFormatOptions = {
				weekday: 'short',
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				timeZone: useUTC ? 'UTC' : undefined,
			};
			const formattedTime = new Intl.DateTimeFormat(
				'en-GB',
				timeOptions
			).format(now);
			const formattedDate = new Intl.DateTimeFormat(
				'en-GB',
				dateOptions
			).format(now);
			const tz = useUTC ? 'UTC' : 'LOCAL';
			setTime(formattedTime);
			setDate(formattedDate.replace(',', '').toUpperCase());
			setTimeZone(tz);
		};

		updateTime(); // Initial call
		if (!fixedDate) {
			const intervalId = setInterval(updateTime, 1000);
			return () => clearInterval(intervalId); // Cleanup on unmount
		}
	}, [useUTC, fixedDate]);

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				gap: 1,
			}}
		>
			<Typography variant='body1'>{`${date}`}</Typography>
			<Typography variant="body1">{`${time}`}</Typography>
			<Choose>
				<When condition={tz}>
					<Divider orientation="vertical" flexItem />
					<Typography variant="subtitle1">{`${timeZone}`}</Typography>
				</When>
			</Choose>
		</Box>
	);
};

export default TimeDisplay;
