// RepeatableJobDialog.tsx

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import cronParser from 'cron-parser';
import cronstrue from 'cronstrue';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { daysOfWeek, hourOptions, makeCronDescription } from '../helpers';
import TimeDisplay from './TimeDisplay';

interface PeriodicTestDialogProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (cron: string) => void;
	/** Optional initial cron expression (expects format: "minute hour day-of-month month day-of-week") */
	initialCron?: string;
	submitError?: string;
}

const PeriodicTestDialog: React.FC<PeriodicTestDialogProps> = ({
	open,
	onClose,
	onSubmit,
	initialCron,
	submitError,
}) => {
	// Only hour and day-of-week are configurable; minute and day-of-month are fixed.
	const [hour, setHour] = useState<string>('*');
	// For day-of-week, use an array of selected values. Default is all days selected.
	const [dayOfWeek, setDayOfWeek] = useState<string[]>([
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
	]);
	const [cronExpression, setCronExpression] = useState<string>('');
	const [cronDescription, setCronDescription] = useState<string>('');
	const [nextRunTime, setNextRunTime] = useState<string>('');
	const [errors, setErrors] = useState<Record<string, string>>({});

	// Initialize state from initialCron when the dialog opens.
	useEffect(() => {
		if (open) {
			if (initialCron && initialCron.trim().length > 0) {
				// Expected format: "minute hour day-of-month month day-of-week"
				const parts = initialCron.trim().split(' ');
				if (parts.length === 5) {
					// We only care about the "hour" and "day-of-week" parts.
					setHour(parts[1]);
					if (parts[4] === '*') {
						setDayOfWeek(['0', '1', '2', '3', '4', '5', '6']);
					} else {
						setDayOfWeek(parts[4].split(','));
					}
				} else {
					console.error(
						"Provided cron expression doesn't have exactly 5 parts. Falling back to defaults."
					);
					setHour('*');
					setDayOfWeek(['0', '1', '2', '3', '4', '5', '6']);
				}
			} else {
				// No initial cron provided; set defaults.
				setHour('*');
				setDayOfWeek(['0', '1', '2', '3', '4', '5', '6']);
			}
		}
		// We want this to run whenever the dialog opens or the initialCron changes.
	}, [open, initialCron]);

	// Recalculate the cron expression, description, and next run time whenever hour or dayOfWeek change.
	useEffect(() => {
		const minuteFixed = '0';
		const dayOfMonthFixed = '*';
		// If all days are selected, use "*" for cron; otherwise, join the selected days with commas.
		const dayOfWeekPart = dayOfWeek.length === 7 ? '*' : dayOfWeek.join(',');

		const cron = `${minuteFixed} ${hour} ${dayOfMonthFixed} * ${dayOfWeekPart}`;
		setCronExpression(cron);

		try {
			const description = makeCronDescription(cron);
			setCronDescription(description);
		} catch (error) {
			setCronDescription('Invalid cron expression');
		}

		try {
			const interval = cronParser.parseExpression(cron, { utc: true });
			const next = interval.next().toDate();
			const formattedNextRun = dayjs(next).toDate().toUTCString();
			setNextRunTime(formattedNextRun);
		} catch (error) {
			setNextRunTime('Invalid cron expression');
		}
	}, [hour, dayOfWeek]);

	const validate = (): boolean => {
		const newErrors: Record<string, string> = {};

		if (!hour.trim()) {
			newErrors.hour = 'Hour is required';
		}
		if (dayOfWeek.length === 0) {
			newErrors.dayOfWeek = 'At least one day must be selected';
		}
		try {
			cronstrue.toString(cronExpression, { locale: 'en' });
		} catch (e) {
			newErrors.cronExpression = 'Invalid cron expression';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = () => {
		if (!validate()) return;
		onSubmit(cronExpression);
		handleClose();
	};

	const handleClose = () => {
		// Reset form fields and errors.
		// (They will be re-initialized from initialCron if provided when the dialog opens again.)
		setHour('*');
		setDayOfWeek(['0', '1', '2', '3', '4', '5', '6']);
		setCronExpression('');
		setCronDescription('');
		setNextRunTime('');
		setErrors({});
		onClose();
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Schedule Your Job</DialogTitle>
			<DialogContent>
				<Grid container spacing={3} sx={{ mt: 1 }}>
					{/* Hour Selection */}
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth error={!!errors.hour}>
							<InputLabel id="hour-label">Hour</InputLabel>
							<Select
								labelId="hour-label"
								value={hour}
								label="Hour"
								onChange={(e) => setHour(e.target.value as string)}
							>
								{hourOptions.map((option) => (
									<MenuItem key={option} value={option}>
										{option === '*'
											? 'Every Hour (*)'
											: option === '*/2'
											? 'Every 2 Hours (*/2)'
											: option === '*/4'
											? 'Every 4 Hours (*/4)'
											: `${option}:00`}
									</MenuItem>
								))}
							</Select>
							{errors.hour && <FormHelperText>{errors.hour}</FormHelperText>}
							<FormHelperText>
								Select the hour when the job should run. "*" means every hour.
							</FormHelperText>
						</FormControl>
					</Grid>

					{/* Toggle Buttons for Days of Week */}
					<Grid item xs={12} sm={6}>
						<Typography variant="subtitle1" gutterBottom>
							Day(s) of Week
						</Typography>
						<ToggleButtonGroup
							value={dayOfWeek}
							onChange={(event, newDays) => {
								if (newDays !== null) {
									setDayOfWeek(newDays);
								}
							}}
							aria-label="Days of Week"
							size="small"
							color="primary"
							sx={{ display: 'flex', flexWrap: 'wrap' }}
						>
							{daysOfWeek.map((day) => (
								<ToggleButton
									key={day.value}
									value={day.value}
									aria-label={day.label}
								>
									{day.label}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
						{errors.dayOfWeek && (
							<FormHelperText error>{errors.dayOfWeek}</FormHelperText>
						)}
						<FormHelperText>
							Toggle the days when the job should run. Selecting all days will
							be treated as "*".
						</FormHelperText>
					</Grid>
				</Grid>
				<Box sx={{ mt: 4 }}>
					<Divider />
					<Typography variant="h4" sx={{ mt: 2 }}>
						Schedule Preview
					</Typography>

					{/* Cron Expression Row */}
					<Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
						<Typography variant="body1" sx={{ minWidth: '180px' }}>
							<strong>Cron Expression:</strong>
						</Typography>
						<Typography sx={{ ml: 1 }} variant="body1">
							{cronExpression}
						</Typography>
					</Box>

					{/* Schedule Row */}
					<Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
						<Typography variant="body1" sx={{ minWidth: '180px' }}>
							<strong>Schedule:</strong>
						</Typography>
						<Typography sx={{ ml: 1 }} variant="body1">
							{cronDescription}
						</Typography>
					</Box>

					{/* Next Run Times */}
					<Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
						<Typography variant="body1" sx={{ minWidth: '180px' }}>
							<strong>Next Run Time (UTC):</strong>
						</Typography>
						<Box sx={{ ml: 1 }}>
							<TimeDisplay
								useUTC
								fixedDate={new Date(nextRunTime)}
								tz={false}
							/>
						</Box>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
						<Typography variant="body1" sx={{ minWidth: '180px' }}>
							<strong>Next Run Time (Local):</strong>
						</Typography>
						<Box sx={{ ml: 1 }}>
							<TimeDisplay fixedDate={new Date(nextRunTime)} tz={false} />
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Cancel
				</Button>
				<Button onClick={handleSubmit} variant="contained" color="primary">
					Save Schedule
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PeriodicTestDialog;
