import { SerializedStyles } from '@emotion/react';
import CopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
	Box,
	IconButton,
	Link,
	TableCell,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { useCopyToClipboard } from '@src/common/hooks/useCopyToClipboard';
import { useState, useCallback } from 'react';
import { usePeriodicTest } from '../hooks/usePeriodicTest';
import { PeriodicTestRun } from '../types';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import PeriodicTestDialog from './PeriodicTestDialog';
import { makeCronDescription } from '../helpers';
import TimeDisplay from './TimeDisplay';

interface PeriodicTestRowProps {
	periodicTest: PeriodicTestRun;
	tableRowStyles: SerializedStyles;
	cb: (message: string) => void;
}

export const PeriodicTestRow = (props: PeriodicTestRowProps) => {
	const {
		periodicTest,
		handleLocalChange,
		handleCommitChanges,
		handleExecuteNow,
		handleScheduleJob,
		handlePauseJob,
		isLoading, // combined loading state
	} = usePeriodicTest({ periodicTest: props.periodicTest });

	const { handleCopy } = useCopyToClipboard(periodicTest.lastJobURL);

	const [dialogOpen, setDialogOpen] = useState(false);

	// New state variables for error handling and local loading
	const [applyError, setApplyError] = useState<string | null>(null);
	const [isApplying, setIsApplying] = useState(false);

	const handleOpenDialog = useCallback(() => {
		setDialogOpen(true);
		setApplyError(null); // Reset error when opening dialog
	}, []);

	const handleCloseDialog = useCallback(() => {
		if (!isApplying) {
			// Prevent closing while applying
			setDialogOpen(false);
			setApplyError(null);
		}
	}, [isApplying]);

	// Modified handleApplyFrequency to handle async operations and errors
	const handleApplyFrequency = useCallback(
		async (newCron: string, startDate?: Date) => {
			setIsApplying(true);
			setApplyError(null);
			try {
				// Create the updated test object immediately
				const updatedTest = { ...periodicTest, frequency: newCron };

				// Update local state
				handleLocalChange(updatedTest);

				// Commit the changes using the updated object
				await handleCommitChanges(updatedTest);

				setDialogOpen(false); // Close dialog only on success
			} catch (error) {
				console.error('Failed to apply frequency', error);
				setApplyError('Failed to update frequency. Please try again.');
			} finally {
				setIsApplying(false);
			}
		},
		[periodicTest, handleCommitChanges, handleLocalChange, setDialogOpen]
	);
	// Example: schedule job (turn isActive=true)
	const onScheduleClick = useCallback(() => {
		handleScheduleJob();
	}, [handleScheduleJob]);

	// Example: run test immediately
	const onExecuteClick = useCallback(() => {
		handleExecuteNow();
	}, [handleExecuteNow]);

	return (
		<TableRow>
			<TableCell>{periodicTest.appName}</TableCell>

			<TableCell>
				<TextField
					id="branch"
					variant="standard"
					fullWidth
					placeholder="default branch"
					value={periodicTest.branch}
					onChange={(e) => handleLocalChange({ branch: e.target.value })}
				/>
			</TableCell>

			<TableCell>
				{periodicTest.lastTriggeredAt
					? new Date(periodicTest.lastTriggeredAt).toLocaleString('en-EU', {})
					: 'N/A'}
			</TableCell>

			<TableCell>
				<Choose>
					<When condition={!!periodicTest.lastJobURL}>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
							<Link
								href={periodicTest.lastJobURL}
								target="_blank"
								rel="noreferrer"
								underline="hover"
							>
								{`${periodicTest.lastJobURL.slice(0, 16)}...`}
							</Link>
							<IconButton size="small" onClick={handleCopy}>
								<CopyIcon fontSize="small" />
							</IconButton>
						</Box>
					</When>
					<Otherwise>N/A</Otherwise>
				</Choose>
			</TableCell>

			<TableCell>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					<Typography
						fontWeight="bold"
						sx={{
							overflow: 'hidden',
							textWrap: 'wrap',
							wordBreak: 'break-word',
						}}
					>
						{makeCronDescription(periodicTest.frequency, { verbose: true })}
					</Typography>
					<IconButton onClick={handleOpenDialog}>
						<EditIcon />
					</IconButton>
				</Box>
			</TableCell>

			<TableCell>
				<Choose>
					<When
						condition={periodicTest.isActive && !!periodicTest.nextTriggerAt}
					>
						<TimeDisplay
							useUTC={true}
							fixedDate={new Date(periodicTest.nextTriggerAt)}
						/>
					</When>
					<Otherwise>
						<Typography>N/A</Typography>
					</Otherwise>
				</Choose>
			</TableCell>

			<TableCell>
				<Typography color={periodicTest.isActive ? 'green' : 'red'}>
					{periodicTest.isActive ? 'Active' : 'Inactive'}
				</Typography>
			</TableCell>

			<TableCell>
				<Choose>
					<When condition={!isLoading}>
						<Tooltip title="Update and Schedule the Job" placement="top">
							<Box component={'span'}>
								<IconButton disabled={isLoading} onClick={onScheduleClick}>
									<ScheduleIcon />
								</IconButton>
							</Box>
						</Tooltip>

						<Tooltip title="Pause Job" placement="top">
							<Box component={'span'}>
								<IconButton
									disabled={!periodicTest.isActive || isLoading}
									onClick={handlePauseJob}
								>
									<PauseIcon />
								</IconButton>
							</Box>
						</Tooltip>
						<Tooltip title="Run Now" placement="top">
							<Box component={'span'}>
								<IconButton onClick={onExecuteClick}>
									<PlayIcon />
								</IconButton>
							</Box>
						</Tooltip>
					</When>
					<Otherwise>
						<LoadingCircularProgress children={''} />
					</Otherwise>
				</Choose>
			</TableCell>

			{dialogOpen && (
				<PeriodicTestDialog
					initialCron={periodicTest.frequency}
					onClose={handleCloseDialog}
					open={dialogOpen}
					onSubmit={(a) => handleApplyFrequency(a)}
					submitError={applyError}
				/>
			)}
		</TableRow>
	);
};
