export enum TestGenerationStatus {
	Queued = 'queued',
	Running = 'running',
	Completed = 'completed',
	Failed = 'failed',
	Aborted = 'aborted',
	Verifying = 'verifying',
	Edited = 'edited',
	PRClosed = 'pr-closed',
	PRMerged = 'pr-merged',
}

export interface TestGeneration {
	id: string;
	runId: string;
	internalTestId: string;
	status: TestGenerationStatus;
	pullRequestURL?: string;
	prClosedAt?: string;
	createdAt: string;
	updatedAt: string;
	completedAt?: string;
	isSuccess?: boolean;
	prJobStatus: TestGenerationPRJobStatus;
	hasTrace: boolean;
}

export enum TestGenerationPRJobStatus {
	Active = 'active',
	Inactive = 'inactive',
}
