import {
	useGetAllPeriodicTestsQuery,
	useCreatePeriodicTestMutation,
} from '@src/modules/internal/api';

export const usePeriodicTestsPage = () => {
	const {
		data: periodicTestRuns,
		refetch,
		isLoading,
		isError,
	} = useGetAllPeriodicTestsQuery();
	const [createPeriodicTest] = useCreatePeriodicTestMutation();

	const handleCreatePeriodicTest = async (appName: string) => {
		try {
			const result = await createPeriodicTest({ appName }).unwrap();
			refetch();
			return result;
		} catch (error) {
			console.log('Failed to create periodic test');
		}
	};

	return {
		periodicTestRuns: periodicTestRuns ?? [],
		refetch,
		isLoading,
		isError,
		handleCreatePeriodicTest,
	};
};
