import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useAdminTableStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const tableContainer = css({
			width: '100%',
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(0.5),
			whiteSpace: 'nowrap',
		});

		const tableRow = css({
			border: 0,
			borderBottom: `1px solid ${theme.colors.borderColor.main}`,
			display: 'grid',
			justifyContent: 'normal',
			gridTemplateColumns: '400px auto 70px',
		});

		return {
			tableRow,
			tableContainer,
		};
	}, [theme]);
};

export const useInternalTableRowStyles = () => {
	return useMemo(() => {
		const hideScrollbar = css`
			&::-webkit-scrollbar {
				display: none;
			}
		`;

		const tableCell = css(hideScrollbar, {
			border: 0,
			alignContent: 'center',
			overflow: 'auto',
			display: 'flex',
		});

		return {
			tableCell,
		};
	}, []);
};
