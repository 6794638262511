import { useTestReport } from '@src/modules/test-runs/pages/test-report/hooks';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { Button, Box } from '@mui/material';
import { useTestReportStyles } from './styles';
import { KeyboardBackspace } from '@mui/icons-material';

export const TestReport = () => {
	const { divRef, isLoading, onBack, lastSuccessfulTest } = useTestReport();
	const styles = useTestReportStyles({ isLoading });

	return (
		<Box component="section" css={styles.section}>
			<Box css={styles.header}>
				<Button
					variant="text"
					css={styles.backToTestRunsButton}
					onClick={onBack}
				>
					<KeyboardBackspace /> Back
				</Button>

				<If condition={!!lastSuccessfulTest}>
					<Button
						variant="text"
						css={styles.lastSuccessfulTestButton}
						onClick={lastSuccessfulTest}
					>
						Go to last successful test
					</Button>
				</If>
			</Box>

			<If condition={isLoading}>
				<LoadingCircularProgress styles={styles.loading}>
					Loading report...
				</LoadingCircularProgress>
			</If>

			<div id="report-placeholder" css={styles.reportDiv} ref={divRef} />
		</Box>
	);
};
