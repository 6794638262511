import { useAuthenticationInfo } from '@src/common/state/volatile/hooks';
import { useGetAllUsersQuery } from '@src/modules/internal/api';


// Hook for support page
export const useSupportPage = () => {
	// Get user authentication information
	const { user } = useAuthenticationInfo();

	// Fetch all users using API query hook
	const { data: users, refetch, isLoading, isError } = useGetAllUsersQuery();

	// Return permissions, users, refetch function for updating, and query status indicators
	return {
		permissions: user.internalPermissions ?? [], // User permissions
		users: users ?? [], // List of users or empty array if undefined
		refetch, // Function to refetch the users if needed
		isLoading, // Loading status
		isError, // Error status
	};
};
