import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
	Alert,
	Box,
	Button,
	Divider,
	IconButton,
	InputAdornment,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { PeriodicTestRunsTable } from './components/PeriodicTestsTable';
import { usePeriodicTestsPage } from './hooks/usePeriodicPage';
import TimeDisplay from './components/TimeDisplay';

export const PeriodicTestsPage: React.FC = () => {
	const { periodicTestRuns, refetch, handleCreatePeriodicTest } =
		usePeriodicTestsPage();
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [appName, setAppName] = useState('');
	const [isAddingApp, setIsAddingApp] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	// Function to handle adding a new user
	const handleAddAppClick = () => {
		setIsAddingApp(true);
	};

	// Function to handle canceling the add user action
	const handleCancelAddUser = () => {
		setIsAddingApp(false);
		setAppName(''); // Clear email input when canceled
	};

	// Function to handle creating the user
	const onCreatePeriodicTest = async () => {
		if (appName) {
			const result = await handleCreatePeriodicTest(appName);
			if (result) {
				handleShowSnackbar('Periodic test created successfully!');
				setAppName('');
				setIsAddingApp(false);
				refetch();
			}
		}
	};
	// Function to show the snackbar
	const handleShowSnackbar = (message: string) => {
		setSnackbarMessage(message);
		setSnackbarOpen(true);
	};

	// Function to close the snackbar
	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	const filteredPeriodicTestRuns = periodicTestRuns.filter((periodicTestRun) =>
		periodicTestRun.appName.toLowerCase().includes(searchQuery.toLowerCase())
	);
	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					margin: '10px',
					alignItems: 'center',
					transition: 'width 0.3s ease', // Smooth transition
					width: isAddingApp ? '100%' : 'auto',
				}}
			>
				<Box>
					<Typography variant="h4">Current Time</Typography>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
						}}
					>
						<TimeDisplay message="LOCAL TIME" />
						<Divider orientation="vertical" flexItem />
						<TimeDisplay message="UTC TIME" useUTC={true} />
					</Box>
				</Box>
				<Choose>
					<When condition={isAddingApp}>
						<Button
							startIcon={<AddIcon />}
							variant="contained"
							onClick={handleAddAppClick}
							sx={{
								transition: 'width 0.3s ease',
							}}
						>
							Add App
						</Button>
					</When>
					<Otherwise>
						<Box
							sx={{
								display: 'flex',
								transition: 'all 0.3s ease',
							}}
						>
							<TextField
								label="Add new periodic job"
								placeholder="App Name"
								variant="outlined"
								fullWidth
								margin="dense"
								value={appName}
								onChange={(e) => setAppName(e.target.value)}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleCancelAddUser}>
												<CloseIcon />
											</IconButton>
											<IconButton onClick={onCreatePeriodicTest}>
												<CheckIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Box>
					</Otherwise>
				</Choose>
			</Box>

			<TextField
				label="Search for APP..."
				variant="outlined"
				fullWidth
				margin="dense"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)} // Update search query
			/>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleCloseSnackbar} severity="success">
					{snackbarMessage}
				</Alert>
			</Snackbar>

			{/* Pass filtered users to UsersTable */}
			<PeriodicTestRunsTable periodicTestRuns={filteredPeriodicTestRuns} />
		</Box>
	);
};
