import api from '@src/api';
import {
	PeriodicTestK8sJobData,
	PeriodicTestRun,
} from '../pages/PeriodicTestsPage/types';
import { ManagedUser } from '../pages/SupportUsersPage/types';

export const supportAdminAPI = api
	.enhanceEndpoints({
		addTagTypes: ['UserByEmail'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getAllUsers: builder.query<ManagedUser[], void>({
				query: () => ({
					url: `support/user/`,
				}),
				transformResponse: (response: { users: ManagedUser[] }) =>
					response.users,
			}),
			getUserByEmail: builder.query<string, { email: string }>({
				query: ({ email }) => ({
					url: `support/user/${email}`,
				}),
			}),
			createSupportUser: builder.mutation<string, { email: string }>({
				query: ({ email }) => ({
					url: `support/user/`,
					method: 'POST',
					body: { email },
				}),
			}),
			deleteSupportUser: builder.mutation<ManagedUser, { email: string }>({
				query: ({ email }) => ({
					url: `support/user/${email}`,
					method: 'DELETE',
				}),
				transformResponse: (response: { user: ManagedUser }) => response.user,
			}),
			refreshUserSeed: builder.mutation<ManagedUser, { email: string }>({
				query: ({ email }) => ({
					url: `support/user/${email}`,
					method: 'PUT',
					successToastMessage: 'User seed refreshed',
				}),
				transformResponse: (response: { user: ManagedUser }) => response.user,
			}),
			createPeriodicTest: builder.mutation<
				PeriodicTestRun,
				{ appName: string }
			>({
				query: ({ appName }) => ({
					url: `support/periodic-test-runs/${appName}`,
					method: 'POST',
					body: { appName },
					makeErrorFromResponse: (response) => {
						return response.data['message']
							? `Failed to create periodic test: ${response.data['message']}`
							: 'Failed to create periodic test';
					},
				}),
			}),

			getAllPeriodicTests: builder.query<PeriodicTestRun[], void>({
				query: () => ({
					url: `support/periodic-test-runs/`,
				}),
			}),
			getPeriodicTest: builder.query<PeriodicTestRun, { id: string }>({
				query: ({ id }) => ({
					url: `support/periodic-test-runs/${id}`,
				}),
			}),

			updatePeriodicTest: builder.mutation<
				Partial<PeriodicTestRun>,
				{ id: string } & Partial<PeriodicTestRun>
			>({
				query: (body) => {
					// Destructure to exclude `appName`
					const { appName, ...filteredBody } = body;

					// Return the API request with the filtered body
					return {
						url: `support/periodic-test-runs/${body.id}`,
						method: 'PUT',
						body: filteredBody,
					};
				},
			}),
			executePeriodicTest: builder.mutation<
				PeriodicTestK8sJobData,
				{ id: string } & Partial<PeriodicTestRun>
			>({
				query: (body) => {
					const { appName, ...filteredBody } = body;
					return {
						url: `support/periodic-test-runs/${body.id}/run`,
						method: 'POST',
						body: filteredBody,
						successToastMessage:
							'Periodic test job successfully dispatched, check Last Job URL for details',
					};
				},
			}),
			deletePeriodicTest: builder.mutation<PeriodicTestRun, { id: string }>({
				query: ({ id }) => ({
					url: `support/periodic-test-runs/${id}`,
					method: 'DELETE',
				}),
			}),
		}),
	});

export const {
	useGetAllUsersQuery,
	useGetUserByEmailQuery,
	useCreateSupportUserMutation,
	useRefreshUserSeedMutation,
	useDeleteSupportUserMutation,
	useGetAllPeriodicTestsQuery,
	useGetPeriodicTestQuery,
	useCreatePeriodicTestMutation,
	useUpdatePeriodicTestMutation,
	useExecutePeriodicTestMutation,
	useDeletePeriodicTestMutation,
} = supportAdminAPI;
