import {
	TestGeneration,
	TestGenerationPRJobStatus,
	TestGenerationStatus,
} from '../types';
import {
	APITestGeneration,
	APITestGenerationPRJobStatus,
	APITestGenerationStatus,
} from './types';

export const transformAPITestGeneration = (
	apiUserStoryTestGeneration: APITestGeneration
): TestGeneration => {
	return {
		id: apiUserStoryTestGeneration.id,
		runId: apiUserStoryTestGeneration.generationBatchId,
		internalTestId: apiUserStoryTestGeneration.internalTestId,
		status: apiUserStoryTestGeneration.isAborted
			? TestGenerationStatus.Aborted
			: transformAPITestGenerationStatus(apiUserStoryTestGeneration.status),
		prClosedAt: apiUserStoryTestGeneration.prClosedAt,
		createdAt: apiUserStoryTestGeneration.createdAt,
		completedAt: apiUserStoryTestGeneration.completedAt,
		updatedAt: apiUserStoryTestGeneration.updatedAt,
		pullRequestURL: apiUserStoryTestGeneration.pullRequestURL,
		isSuccess: apiUserStoryTestGeneration.isSuccess,
		prJobStatus: transformAPITestGenerationPRJobStatus(
			apiUserStoryTestGeneration.prJobStatus ?? 'unknown'
		),
		hasTrace: !!apiUserStoryTestGeneration.hasTrace,
	};
};

export const transformAPITestGenerationStatus = (
	apiTestGenerationStatus: APITestGenerationStatus
): TestGenerationStatus => {
	switch (apiTestGenerationStatus) {
		default:
		case APITestGenerationStatus.Queued:
			return TestGenerationStatus.Queued;
		case APITestGenerationStatus.Running:
			return TestGenerationStatus.Running;
		case APITestGenerationStatus.Completed:
			return TestGenerationStatus.Completed;
		case APITestGenerationStatus.Verifying:
			return TestGenerationStatus.Verifying;
		case APITestGenerationStatus.Failed:
			return TestGenerationStatus.Failed;
		case APITestGenerationStatus.PRClosed:
			return TestGenerationStatus.PRClosed;
		case APITestGenerationStatus.PRMerged:
			return TestGenerationStatus.PRMerged;
	}
};

const transformAPITestGenerationPRJobStatus = (
	apiStatus: APITestGenerationPRJobStatus
): TestGenerationPRJobStatus => {
	switch (apiStatus) {
		case 'active':
		case 'delayed':
		case 'waiting':
			return TestGenerationPRJobStatus.Active;
		default:
			return TestGenerationPRJobStatus.Inactive;
	}
};
