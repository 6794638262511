import {
	TableContainer,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import { useAdminTableStyles } from '../../styles';
import { PeriodicTestRow } from './PeriodicTestsRow';
import { PeriodicTestRun } from '../types';

interface PeriodicTestRunsTableProps {
	periodicTestRuns: PeriodicTestRun[];
}
export const PeriodicTestRunsTable = ({
	periodicTestRuns,
}: PeriodicTestRunsTableProps) => {
	const styles = useAdminTableStyles();
	return (
		<TableContainer
			component={Box}
			css={{ ...styles.tableContainer, columnCount: 2 }}
		>
			<Table size="small">
				<TableHead>
					<TableRow>
						{[
							'appName',
							'Branch',
							'Last Triggered At',
							'Last Job URL',
							'Frequency',
							'Next Run',
							'Status',
							'Actions',
						].map((header, index) => (
							<TableCell key={index}>{header}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{periodicTestRuns.map((run) => (
						<PeriodicTestRow
							key={run.id}
							periodicTest={run}
							tableRowStyles={styles.tableRow}
							cb={() => {}}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
