export type APITestSuiteRun = {
	id: string;
	branch: string;
	commitHash: string;
	tags: string[];
	name: string; // this will be PR name or last commit message
	environment: string;
	mode: 'normal' | 'refactor' | 'auto-heal';
	pullRequestURL?: string;
	status: APITestSuiteRunStatus;
	passed: number | null;
	failed: number | null;
	healed: number | null;
	uploadsCompleted: boolean;
	startedAt: string; // date
	endedAt: string; // date
};

export enum APITestSuiteRunStatus {
	Running = 'running',
	Passed = 'passed',
	// Healed = 'healed',
	Failed = 'failed',
	// Cancelled = 'cancelled',
}
export type LastSuccessfulTestReportURLData = {
	testSuiteRunId: string;
	testId: string;
};

export type SingleTestRunStatusData = {
	success: boolean;
};
