import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import { UsersTableRow } from './UserRow';
import { useAdminTableStyles } from '../../styles';
import { ManagedUser } from '../types';

interface UsersTableProps {
	users: ManagedUser[];
	onShowMessage: (message: string) => void; // Add this prop
}

export const UsersTable = ({ users, onShowMessage }: UsersTableProps) => {
	const styles = useAdminTableStyles();
	const handleButtonClick = (userName: string) => {
		// Triggering the snackbar pop-up with a custom message
		onShowMessage(`User ${userName} was selected`);
	};
	return (
		<TableContainer component={Box} css={styles.tableContainer}>
			<Table size="small">
				<TableHead>
					<TableRow css={styles.tableRow}>
						{['User ', 'Token'].map((header, index) => (
							<TableCell key={index}>{header}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{users.map((user) => (
						<UsersTableRow
							key={user.token}
							user={user}
							tableRowStyles={styles.tableRow}
							cb={handleButtonClick}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
