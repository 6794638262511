import {
	useRefreshUserSeedMutation,
	useDeleteSupportUserMutation,
} from '@src/modules/internal/api';
import { ManagedUser } from '../types';

export const useUsersTableRow = ({ user }: { user: ManagedUser }) => {
	const [refreshUser] = useRefreshUserSeedMutation();
	const [deleteUser] = useDeleteSupportUserMutation();
	return {
		refreshUser,
		deleteUser,
	};
};
