export enum APITestGenerationStatus {
	Queued = 'queued',
	Running = 'running',
	Completed = 'completed',
	Failed = 'failed',
	Verifying = 'verifying',
	PRClosed = 'pr-closed',
	PRMerged = 'pr-merged',
}

export interface APITestGeneration {
	id: string;
	generationBatchId: string;
	internalTestId: string;
	userStoryId: string;
	status: APITestGenerationStatus;
	isAborted: boolean;
	createdAt: string;
	updatedAt: string;
	completedAt?: string;
	pullRequestURL?: string;
	prClosedAt?: string;
	isSuccess?: boolean | null;
	prJobStatus?: APITestGenerationPRJobStatus;
	hasTrace: boolean;
}

export type APITestGenerationPRJobStatus =
	| 'completed'
	| 'failed'
	| 'active'
	| 'delayed'
	| 'waiting'
	| 'waiting-children'
	| 'unknown'
	| 'not_found';
