import cronstrue from 'cronstrue';
export const makeCronDescription = (
	cron: string,
	options?: {
		verbose: boolean;
	}
) => {
	return cronstrue.toString(cron, {
		locale: 'en',
		use24HourTimeFormat: true,
		verbose: options?.verbose,
	});
};

export const hourOptions = [
	'*',
	'*/2',
	'*/4',
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
];

export const daysOfWeek = [
	{ value: '0', label: 'Sun' },
	{ value: '1', label: 'Mon' },
	{ value: '2', label: 'Tue' },
	{ value: '3', label: 'Wed' },
	{ value: '4', label: 'Thu' },
	{ value: '5', label: 'Fri' },
	{ value: '6', label: 'Sat' },
];
